@import '../../../index.module.scss';

.others{
    float: left;
    width: 100%;
    height: fit-content;
    background-color: #DDFFF5;

    .popupMessage{
        position: fixed;
        background-color: white;
        box-shadow: 0.1rem 0.1rem 0.5rem 0.2rem grey;
        top: -20%;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 1rem;
        padding: 2rem 5rem;
        z-index: 1000;

        @media (max-width: $break-point-600) {
            padding: 2rem 2rem;
        }

        p{
            font-size: 3rem;
            width: 50rem;
            text-align: center;

            @media (max-width: $break-point-768) {
                font-size: 2.5rem;
                max-width: 40rem;
            }

            @media (max-width: $break-point-600) {
                font-size: 2rem;
                width: 30rem;
            }
        }
    }

    .videoSection{
        display: flex;
        flex-direction: column;
        align-items: center;

        h3{
            font-size: 3.6rem;
            font-weight: 400;
            text-align: center;
            margin-top: 12rem;

            @media (max-width: $break-point-1280) {
                font-size: 3rem;
            }

            @media (max-width: $break-point-992) {
                font-size: 2.5rem;
            }

            @media (max-width: $break-point-992) {
                width: 70%;
            }

            @media (max-width: $break-point-600) {
                margin-top: 5rem;
            }
        }

        .video{
            margin-top: 6rem;
            width: 80%;
            display: flex;
            justify-content: center;
            // height: 70rem;
            // height: 80vh;
            // background-color: grey;

            video{
                width: 100%;
            }

            @media (max-width: $break-point-1280) {
                // height: 80vh;
            }

            @media (max-width: $break-point-992) {
                // height: 70vh;
            }

            @media (max-width: $break-point-992) {
                // height: 60vh;
            }

            @media (max-width: $break-point-600) {
                // height: 30vh;
            }
        }
    }

    .countingSection{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 12rem;

        @media (max-width: $break-point-1280) {
            margin-top: 10rem;
        }

        p.last{
            margin-top: 6rem;
        }

        p{
            font-size: 3.6rem;
            text-align: center;

            @media (max-width: $break-point-1280) {
                font-size: 3rem;
            }

            @media (max-width: $break-point-600) {
                width: 70%;
                font-size: 2.5rem;
            }
        }

        h2{
            font-size: 15rem;
            margin-top: 6rem;
            font-weight: 500;
            background: linear-gradient(#089C72, #317B65);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            @media (max-width: $break-point-1280) {
                font-size: 10rem;
            }

            @media (max-width: $break-point-600) {
                font-size: 8rem;
            }
        }
    }

    .features{
        margin-top: 12rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (max-width: $break-point-600) {
            margin-top: 10rem;
        }

        p{
            font-size: 3.6rem;
            text-align: center;
            font-weight: 500;

            @media (max-width: $break-point-1280) {
                font-size: 3rem;
            }

            @media (max-width: $break-point-600) {
                font-size: 2.5rem;
            }
        }

        h2{
            color: #00C88F;
            font-size: 7rem;
            font-weight: 700;
            margin-top: 1rem;
            text-align: center;
            margin: auto;
            width: 80%;
            @media (max-width: $break-point-1280) {
                font-size: 5rem;
            }

            @media (max-width: $break-point-600) {
                font-size: 4rem;
            }

        }

        .featureList{
            margin-top: 9rem;
            width: 80%;
            margin-bottom: 5rem;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            gap: 9rem;

            @media (max-width: $break-point-600) {
                margin-top: 6rem;
                gap: 6rem;
            }
        }
    }

    .flow{
        float: left;
        width: 100%;
        margin-top: 12rem;

        @media (max-width: $break-point-600) {
            margin-top: 10rem;
        }

        .heading{
            display: flex;
            flex-direction: column;
            gap: 1rem;
            align-items: center;
            width: inherit;
            p{
                font-size: 3.6rem;
                font-weight: 500;
                text-align: center;
                width: 80%;

                @media (max-width: $break-point-1280) {
                    font-size: 3rem;
                }

                @media (max-width: $break-point-600) {
                    font-size: 2.5rem;
                }
            }
            h1{
                font-size: 7rem;
                font-weight: 700;
                color: #00C88F;
                text-align: center;
                width: 80%;

                @media (max-width: $break-point-1280) {
                    font-size: 5rem;
                }

                @media (max-width: $break-point-600) {
                    font-size: 4rem;
                }
            }
        }

        .devices{
            padding: 0rem 12%;
            height: fit-content;
            margin: auto;
            margin-top: 6rem;
            
            h2{
                font-size: 7.6rem;
                font-weight: 700;

                @media (max-width: $break-point-1280) {
                    font-size: 6rem;
                }

                @media (max-width: $break-point-992) {
                    font-size: 4rem;
                }

                @media (max-width: $break-point-600) {
                    
                    font-size: 2rem;
                }
                
            }
            .deviceL{
                display: flex;
                gap: 20rem;
                align-items: center;
                margin-bottom: 1rem;

                @media (max-width: $break-point-1280) {
                    gap: 15rem;
                }

                @media (max-width: $break-point-992) {
                    gap: 10rem;
                }

                @media (max-width: $break-point-768) {
                    gap: 5rem;
                }

                @media (max-width: $break-point-600) {
                    width: 100%;
                }
            }
            .deviceR{
                display: flex;
                flex-direction: row-reverse;
                gap: 20rem;
                align-items: center;
                margin-bottom: 1rem;

                @media (max-width: $break-point-1280) {
                    gap: 15rem;
                }

                @media (max-width: $break-point-1280) {
                    gap: 10rem;
                }

                @media (max-width: $break-point-768) {
                    gap: 5rem;
                }

                @media (max-width: $break-point-600) {
                    // background-color: aqua;
                    width: 100%;
                }
            }
            .img{
                width: 42.5rem;
                overflow: hidden;

                img{
                    width: 100%;
                }

                @media (max-width: $break-point-1280) {
                    width: 40%;
                }

                @media (max-width: $break-point-768) {
                    width: 50%;
                }
            }
            .ltor{
                padding: 0% 15%;
                position: relative;
                svg{
                    width: 100%;
                    height: 100%;
                }
            }

            .rtol{
                padding: 0% 15%;
                position: relative;

                svg{
                    width: 100%;
                    height: 100%;
                }
            }

            .lastLine{
                width: 50%;
                margin: auto;
                // height: 5rem;
                display: flex;
                flex-direction: row-reverse;
                // margin-bottom: 6.2rem;



                .lineL{
                    width: 50%;
                    height: 100%;
                    // position: relative;
                    // border-right: 0.3rem dashed black;
                    // border-bottom: 0.3rem dashed black;

                    svg{
                        width: 100%;
                        height: 100%;
                    }

                    // &::after{
                    //     content: '';
                    //     position: absolute;
                    //     height: 5rem;
                    //     left: 0%;
                    //     top: 100%;
                    //     border-left: 0.3rem dashed black;
                    // }
                }
            }

            p{
                font-size: 3.6rem;
                font-weight: 400;
                text-align: center;

                @media (max-width: $break-point-1280) {
                    font-size: 3rem;
                }

                @media (max-width: $break-point-600) {
                    font-size: 2.5rem;
                }
            }
        }
    }

    .lastSection{
        float: left;
        width: 100%;
        margin-top: 12rem;

        p{
            text-align: center;
            font-size: 3.6rem;
            font-weight: 500;
            margin: auto;
            width: 80%;

            @media (max-width: $break-point-1280) {
                font-size: 3rem;
            }

            @media (max-width: $break-point-600) {
                font-size: 2.5rem;
            }
        }

        h1{
            margin-top: 1rem;
            text-align: center;
            color: #00C88F;
            font-size: 7rem;
            font-weight: 700;

            @media (max-width: $break-point-1280) {
                font-size: 6rem;
            }

            @media (max-width: $break-point-600) {
                width: 80%;
                margin: auto;
                font-size: 5rem;
            }
        }

        .ready{
            margin-top: 9rem;
            display: flex;
            gap: 3.6rem;
            position: relative;
            justify-content: center;

            @media (max-width: $break-point-768) {
                gap: 2rem;
            }

            @media (max-width: $break-point-600) {
                flex-direction: column;
                align-items: center;
            }

            input{
                width: 55rem;
                padding: 2.3rem 3rem;
                border-radius: 5rem;
                outline: none;
                background-color: transparent;
                font-size: 2rem;
                font-weight: 400;

                @media (max-width: $break-point-1280) {
                    width: 45rem;
                }

                @media (max-width: $break-point-768) {
                    width: 40rem;
                }

                @media (max-width: $break-point-600) {
                    width: 80%;
                    font-size: 1.6rem;
                    padding: 1.5rem 2rem;
                }

                // &::placeholder{
                //     // font-size: 2rem;
                //     // font-weight: 400;
                // }
            }

            button{
                background-color: #009B6B;
                border: none;
                padding: 1.7rem 5rem;
                font-size: 3rem;
                font-weight: 600;
                border-radius: 5rem;
                color: white;
                box-shadow: 0.2rem 0.5rem 1rem 0rem rgba(0, 0, 0, 15%);
                cursor: pointer;

                @media (max-width: $break-point-1280) {
                    font-size: 2.5rem;
                    padding: 1.5rem 3rem;
                }

                @media (max-width: $break-point-600) {
                    font-size: 2rem;
                    padding: 1.5rem 3rem;
                }
            }

            .error{
                position: absolute;
                display: none;
                top: 100%;
                // left: 0%;
                p{
                    font-size: 1.6rem;
                    color: red;
                }
            }
        }

        .benefits{
            display: flex;
            flex-wrap: wrap;
            gap: 9rem;
            margin-top: 5rem;
            justify-content: center;

            @media (max-width: $break-point-992) {
                gap: 7rem;
            }

            @media (max-width: $break-point-600) {
                padding: 0% 20%;
                gap: 4rem;
            }

            div{
                display: flex;
                align-items: center;
                gap: 1rem;

                @media (max-width: $break-point-600) {
                    gap: 0rem;
                }

                img{
                    height: 2rem;
                    width: 2rem;
                }

                p{
                    font-size: 2rem;
                    font-weight: 400;

                    @media (max-width: $break-point-600) {
                        font-size: 1.6rem;
                    }
                }
            }
        }
    }

    .footer{
        width: 100%;
        float: left;
        display: flex;
        justify-content: center;
        margin-top: 12rem;
        margin-bottom: 6rem;

        @media (max-width: $break-point-600) {
            margin-top: 8rem;
        }

        .footerMain{
            width: 73%;
            border-radius: 10rem;
            padding: 4rem 9rem;
            display: flex;
            align-items: center;
            justify-content: center;
            // justify-content: space-evenly;
            gap: 6rem;
            background-color: #003726;

            @media (max-width : $break-point-1280) {
                padding: 2rem 0rem;
            }

            @media (max-width : $break-point-992) {
                padding: 1.5rem 2rem;
                justify-content: space-evenly;
            }

            img{
                height: 7rem;

                @media (max-width : $break-point-600) {
                    height: 4rem;
                }
            }

            p{
                font-size: 3rem;
                font-weight: 400;
                color: white;

                @media (max-width : $break-point-1280) {
                    font-size: 2.8rem;
                }

                @media (max-width : $break-point-600) {
                    font-size: 1.8rem;
                }
            }
        }
    }
}