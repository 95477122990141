@import '../index.module.scss';

.privacy{
    width: 100%;
    height: fit-content;
    position: relative;
    top: 0%;
    background-color: white;

    .heading{
        width: 100%;
        height: 14rem;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10rem;
        padding: 0% 10%;
        background-image: url('./home/img/section1.webp');

        @media (max-width: $break-point-768) {
            height: 10rem;
            gap: 5rem;
        }

        @media (max-width: $break-point-600) {
            gap: 3rem;
        }

        .logo{
            height: 70%;

            img{
                height: 100%;
            }
        }

        .headingName{
            h3{
                font-size: 3rem;
                font-weight: 600;
                color: white;

                @media (max-width: $break-point-768) {
                    font-size: 2.5rem;
                }
            }
        }
    }

    .contentPrivacy{
        background-color: bisque;
        background-color: white;
        padding: 3% 20% 5% 20%;

        @media (max-width: $break-point-1280) {
            padding: 3% 15% 5% 15%;
        }

        @media (max-width: $break-point-768) {
            padding: 3% 10% 5% 10%;
        }

        @media (max-width: $break-point-600) {
            padding: 3% 8% 5% 8%;
        }

        p{
            font-size: 1.6rem;
            text-align: justify;
            margin-top: 2.5rem;

            @media (max-width: $break-point-600) {
                font-size: 1.4rem;
            }
        }

        h4{
            font-size: 2rem;
            margin-top: 2.5rem;

            @media (max-width: $break-point-600) {
                font-size: 1.8rem;
            }
        }

        ul{
            margin-left: 5rem;
            margin-top: 2.5rem;

            li{
                font-size: 1.6rem;
                margin-top: 1rem;

                @media (max-width: $break-point-600) {
                    font-size: 1.4rem;
                }
            }
        }
    }

}