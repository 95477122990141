@import '../../../index.module.scss';
.box{
    background-image: url('../img/featurebg.png');
    background-size: 100% 100%;
    width: 40.1rem;
    height: 30.3rem;
    border-radius: 10rem;
    background-color: aqua;
    box-shadow: 0.2rem 0.5rem 2rem 0rem rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    transition: 0.3s;

    @media (max-width: $break-point-1280) {
        width: 35rem;
        height: 26.4rem;
    }

    @media (max-width: $break-point-600) {
        width: 30rem;
        height: 20rem;
        border-radius: 8rem;
    }

    &:hover{
        transform: translateY(-10px);
    }

    .boxImg{
        display: flex;
        justify-content: center;
        img{
            @media (max-width: $break-point-600) {
                width: 80%;
            }
        }
    }

    .boxText{
        p{
            font-size: 3rem;
            color: white;

            @media (max-width: $break-point-1280) {
                font-size: 2.5rem;
            }

            @media (max-width: $break-point-600) {
                font-size: 2rem;
            }
        }
    }
}