@import '../../../index.module.scss';



@keyframes leftbottom {
    0% {transform: rotate(0deg);}
    100% {transform: rotate(360deg);}
}


.join{
    float: left;
    height: 100vh;
    width: 100%;
    background-image: url('../img/section1.webp');
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;
    z-index: -1;
    
    .home_animation{
        height: 55rem;
        width: 55rem;
        border: 0.4rem solid $theme-color;
        position: absolute;
        top: 70%;
        left: 78%;
        border-radius: 50%;
        animation-name: example;
        animation-duration: 5s;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        animation-timing-function: ease-in-out;

        @keyframes example {
            0% {transform: rotate(40deg);}
            100% {transform: rotate(-60deg);}
        }

        @media (max-width: $break-point-1280) {
            animation-duration: 4s;
            @keyframes example {
                0% {transform: rotate(20deg);}
                100% {transform: rotate(-50deg);}
            }
        }

        .inner_circle{
            height: 40rem;
            width: 40rem;
            border: 0.4rem solid $theme-color;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        .animation_blocks{
            background-color: transparent;
            height: 1.4rem;
            width: 1.4rem;
            position: absolute;
            left: 30%;
            transform: rotate(25deg);
            top: 2%;

            .animation_right{
                background-color: #63C8AA;
                position: absolute;
                height: 100%;
                width: 100%;
                right: 100%;
            }
            .animation_left{
                background-color: #63C8AA;
                position: absolute;
                height: 100%;
                width: 100%;
                left: 100%;
            }
            .animation_top{
                background-color: #0F4735;
                position: absolute;
                height: 100%;
                width: 100%;
                top: 100%;
            }
            .animation_bottom{
                background-color: #0F4735;
                position: absolute;
                height: 100%;
                width: 100%;
                bottom: 100%;
            }
        }
    }

    .text{
        width: 100%;
        top: 50%;
        transform: translateY(-40%);
        position: absolute;
        z-index: 1000;
        h2{
            font-size: 7rem;
            font-weight: 500;
            text-align: center;
            color: white;

            @media (max-width: $break-point-1280) {
                font-size: 6rem;
            }

            @media (max-width: $break-point-992) {
                font-size: 5rem;
            }

            @media (max-width: $break-point-768) {
                font-size: 4rem;
            }

            @media (max-width: $break-point-600) {
                font-size: 3.5rem;
            }
        }
        h3{
            font-size: 3rem;
            font-weight: 500;
            margin-top: 9rem;
            text-align: center;
            color: white;

            @media (max-width: $break-point-1280) {
                font-size: 2.5rem;
            }

            @media (max-width: $break-point-992) {
                font-size: 2rem;
            }

            @media (max-width: $break-point-600) {
                margin-top: 5rem;
                font-size: 1.8rem;
                font-weight: 400;
            }
            
        }
        p{
            font-size: 7rem;
            font-weight: 400;
            margin-top: 2rem;
            text-align: center;
            color: white;

            @media (max-width: $break-point-1280) {
                font-size: 6rem;
            }

            @media (max-width: $break-point-992) {
                font-size: 5rem;
            }

            @media (max-width: $break-point-768) {
                font-size: 4rem;
            }

            @media (max-width: $break-point-600) {
                font-size: 3rem;
            }
        }
        p.info{
            font-size: 2rem;
            font-weight: 400;
            margin-top: 3rem;
            text-align: center;

            @media (max-width: $break-point-1280) {
                font-size: 1.8rem;
            }

            @media (max-width: $break-point-992) {
                font-size: 1.6rem;
            }
        }

        .earlyAccess{
            margin-top: 3rem;
            display: flex;
            justify-content: center;
            gap: 3.5rem;

            @media (max-width: $break-point-992) {
                gap: 2rem;
            }

            @media (max-width: $break-point-600) {
                flex-direction: column;
                align-items: center;
            }

            input{
                width: 55rem;
                border-radius: 5rem;
                border: 0.1rem solid white;
                outline: none;
                padding: 2.3rem 3rem;
                background-color: transparent;
                font-size: 2rem;
                font-weight: 400;
                color: #BFBFBF;

                @media (max-width: $break-point-992) {
                    width: 40rem;
                }

                @media (max-width: $break-point-600) {
                    padding: 1.5rem 2rem;
                    width: 80%;
                    font-size: 1.6rem;
                }

                &::placeholder{
                    color: #BFBFBF;
                }
            }

            button{
                height: 7rem;
                font-size: 3rem;
                font-weight: 600;
                color: white;
                padding: 0rem 5rem;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 5rem;
                background-color: $theme-color;
                border: none;
                cursor: pointer;
                box-shadow: 0.2rem 0.5rem 1rem 0rem rgba(0, 0, 0, 15%);

                @media (max-width: $break-point-992) {
                    padding: 0rem 3rem;
                    font-size: 2.5rem;
                }

                @media (max-width: $break-point-768) {
                    padding: 0rem 2.5rem;
                    font-size: 2.5rem;
                    font-weight: 500;
                }

                @media (max-width: $break-point-600) {
                    padding: 1rem 2.5rem;
                    height: fit-content;
                    font-size: 2rem;
                }
            }
        }
    }

    .left_bottom_animation{
        height: 8.5rem;
        width: 8.5rem;
        position: absolute;
        z-index: 10;
        top: 80%;
        left: 8%;
        animation-name: leftbottom;
        animation-duration: 5s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;

        @media (max-width: $break-point-992) {
            left: 5%;
        }

        @media (max-width: $break-point-600) {
            height: 6rem;
            width: 6rem;
        }

        .innercircle{
            height: 100%;
            width: 100%;
            position: absolute;
            border-radius: 50%;
            border: 0.7rem solid #083527;

            @media (max-width: $break-point-600) {
                border: 0.5rem solid #083527;
            }
        }

        .innerLine{
            width: 4rem;
            height: 0.6rem;
            position: absolute;
            top: 50%;
            left: -1.5rem;
            transform: translateY(-50%);
            border-radius: 2rem;
            background-color: #63C8AA;

            @media (max-width: $break-point-600) {
                width: 4rem;
                height: 0.4rem;
            }

        }
    }

    .top_left_animation{
        height: 5.6rem;
        width: 7.2rem;
        position: absolute;
        z-index: 10;
        top: 25%;
        left: 19%;

        @media (max-width: $break-point-992) {
            left: 10%;
        }

        .triangle_one{
            position: absolute;
            bottom: 0%;
            top: 20%;
            animation-name: triangleone;
            animation-duration: 5s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;

            @keyframes triangleone {
                0% {transform: rotate(0deg);}
                100% {transform: rotate(360deg);}
            }
        }

        .triangle_two{
            position: absolute;
            right: 0%;
            animation-name: triangletwo;
            animation-duration: 5s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;

            @keyframes triangletwo {
                0% {transform: rotate(0deg);}
                100% {transform: rotate(360deg);}
            }
        }
        
    }

    .top_right_animation{
        height: 6.8rem;
        width: 7.6rem;
        position: absolute;
        z-index: 10;
        top: 25%;
        right: 19%;

        @media (max-width: $break-point-992) {
            right: 10%;
        }

        .circle_one{
            height: 5.1rem;
            width: 5.1rem;
            position: absolute;
            border-radius: 50%;
            background-color: #13C28E;
            animation-name: circleone;
            animation-duration: 2s;
            animation-iteration-count: infinite;
            animation-direction: alternate;
            animation-timing-function: ease-in-out;

            @keyframes circleone {
                0% {top: -10%; left: 0%;}
                100% {top: 20%; left: 0%;}
            }
        }

        .circle_two{
            height: 5.5rem;
            width: 5.5rem;
            position: absolute;
            // bottom: 0%;
            // left: 0%;
            border-radius: 50%;
            border: 0.4rem solid #083527;
            animation-name: circletwo;
            animation-duration: 2s;
            animation-iteration-count: infinite;
            animation-direction: alternate;
            animation-timing-function: ease-in-out;

            @keyframes circletwo {
                0% {bottom: 0%; left: 0%;}
                100% {bottom: 20%; left: 0%;}
            }
        }
    }
}