@import '../../../index.module.scss';
.nav{
    position: absolute;
    width: 100%;
    height: 10rem;
    top: 5%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0% 5%;
    // background-color: aqua;

    .logo{
        // background-color: antiquewhite;
        height: inherit;
        display: flex;
        align-items: center;
        width: 40%;

        @media (max-width: $break-point-600) {
            width: auto;
            width: 10%;
            // background-color: aqua;
        }

        img{
            height: 100%;

            @media (max-width: $break-point-600) {
                height: 80%;
            }
        }
    }

    .subscribe{
        // background-color: brown;
        height: inherit;
        width: 40%;
        display: flex;
        align-items: center;
        flex-direction: row-reverse;

        @media (max-width: $break-point-600) {
            width: auto;
        }

        a{
            background-color: $theme-color;
            color: white;
            border-radius: 5rem;
            text-decoration: none;
            font-size: 2rem;
            padding: 1.2rem 2rem;
            box-shadow: 0.2rem 0.5rem 1rem 0rem rgba(0, 0, 0, 15%);
            
            @media (max-width: $break-point-600) {
                font-size: 1.6rem;
                padding: 1rem 1.6rem;
            }
            span{
                font-weight: 300;
            }
        }
    }
}