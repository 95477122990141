@import '../index.module.scss';

.whiteBgMain{
    height: 100vh;
    width: 100%;
    background-color: white;
    position: relative;
    z-index: 999;

    .subscribeUnsubscribe{
        height: 100vh;
        width: 100%;
        float: left;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        z-index: 1000;
        background-image: url('../components/home/img/section1.png');
        background-repeat: no-repeat;
        background-size: cover;
    
        
    
        div.main{
            background-color: white;
            background-color: #232323;
            box-shadow: 0.1rem 0.1rem 0.5rem 0.5rem rgba(0, 0, 0, 0.152);
            padding: 5rem 5rem;
            border-radius: 2rem;
    
            @media (max-width: $break-point-600) {
                width: 80%;
            }
    
            @media (max-width: 500px) {
                width: 90%;
                padding: 3rem 3rem;
            }
    
            div{
                display: flex;
                justify-content: center;
                margin-top: 2rem;
                margin-bottom: 2rem;
    
                img{
                    min-height: 10rem;
    
                    @media (max-width: 500px) {
                        min-height: 6rem;
                    }
                }
            }
    
            h1{
                font-size: 4rem;
                margin-top: 2rem;
                color: white;
    
                @media (max-width: $break-point-600) {
                    font-size: 3rem;
                }
    
                @media (max-width: 500px) {
                    font-size: 2rem;
                }
            }
            p{
                font-size: 2rem;
                margin-top: 1rem;
                max-width: 40rem;
                color: white;
    
                @media (max-width: $break-point-600) {
                    font-size: 1.8rem;
                }
    
                @media (max-width: 500px) {
                    font-size: 1.6rem;
                }
            }
            a{
                text-decoration: none;
                font-size: 2rem;
                color: #5891FF;
                text-decoration: underline;
                display: flex;
                align-items: center;
                gap: 1rem;
    
                @media (max-width: $break-point-600) {
                    font-size: 1.6rem;
                }
    
                @media (max-width: 500px) {
                    font-size: 1.4rem;
                }
    
                svg{
                    height: 3rem;
                    width: 3rem;
    
                    @media (max-width: 500px) {
                        height: 2rem;
                        width: 2rem;
                    }
                }
            }
    
            img:nth-child(1){
                height: 6rem;
            }
        }
    }
}

