@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800&display=swap');
*{
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
    font-family: 'Rubik', sans-serif;
}
html{
    font-size: 62.5%;
    scroll-behavior: smooth;
}

$theme-color : #009B6B;
$break-point-1280 : 1281px;
$break-point-992 : 992px;
$break-point-768 : 768px;
$break-point-600 : 600px;